<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1>Manage Add/Edit RFQ</h1>
      </b-col>
      <b-col class="text-right">
        <b-button
          v-if="+$route.query.id"
          :variant="openSection ? 'primary' : 'outline-primary'"
          style="margin-bottom: 7px"
          class="btn-icon rounded-circle mr-2"
          @click="openSection = !openSection"
        >
          <feather-icon icon="MessageCircleIcon" size="16" />
        </b-button>
        <b-button
          v-b-modal.modal-manage
          v-if="+$route.query.id"
          variant="danger"
          style="margin-bottom: 7px"
          class="btn-icon rounded-circle"
        >
          <feather-icon icon="Trash2Icon" size="16" />
        </b-button>

        <Modal modalId="modal-manage" @onChange="(v) => onDelete()" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" :md="openSection ? '9' : '12'">
        <b-card>
          <b-tabs v-model="tabIndex" fill>
            <b-tab title="Information" active>
              <TabInformation
                @onTabChange="(v) => (tabIndex = v)"
                ref="informationTabRefs"
              />
            </b-tab>
            <b-tab title="Parameters">
              <TabParameters ref="parametersTabRefs" />
            </b-tab>
            <b-tab title="Logs">
              <TabLogs ref="logsTabRefs" />
            </b-tab>
            <b-tab title="Comments">
              <TabComments ref="commentsTabRefs" />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>

      <b-col cols="12" :md="openSection ? '3' : '12'" v-if="openSection">
        <b-card no-body>
          <b-tabs v-model="tabIndexSection" fill>
            <b-tab active>
              <template #title>
                <small>Comments</small>
              </template>
              <SectionComments ref="commentsRefs" />
            </b-tab>
            <b-tab>
              <template #title>
                <small>Logs</small>
              </template>
              <SectionLogs ref="logsRefs" />
            </b-tab>
            <b-tab>
              <template #title>
                <small>Approval</small>
              </template>
              <SectionApprovals @onChangeStatus="(v) => onChangeStatus(v)" />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import reportsDesignerApi from "@api/reports_designer";

import TabInformation from "./tabs/TabInformation";
import TabParameters from "./tabs/TabParameters";
import TabLogs from "./tabs/TabLogs";
import TabComments from "./tabs/TabComments";

import SectionComments from "./sections/SectionComments";
import SectionLogs from "./sections/SectionLogs";
import SectionApprovals from "./sections/SectionApprovals";

export default {
  name: "ReportsDesignerManage",
  components: {
    TabInformation,
    TabParameters,
    TabLogs,
    TabComments,

    SectionComments,
    SectionLogs,
    SectionApprovals,
  },
  data: () => ({
    gstPercent: 7,
    approvalStatus: "",
    openSection: false,
    tabIndex: 0,
    tabIndexSection: 0,
  }),
  watch: {
    tabIndex(v) {
      if (+this.$route.query.id) {
        if (v == 1) {
          this.$refs.parametersTabRefs.loadReportsParameter();
        }

        if (v == 2) {
          this.$refs.logsTabRefs.loadLogs();
        }

        if (v == 3) {
          this.$refs.commentsTabRefs.loadTimelineHeader();
        }
      }
    },
    tabIndexSection(v) {
      if (+this.$route.query.id) {
        if (v == 0) {
          this.$refs.commentsRefs.loadTimelineHeader();
        }

        if (v == 1) {
          this.$refs.logsRefs.loadLogs();
        }
      }
    },
  },
  mounted() {},
  methods: {
    onChangeStatus(data) {
      this.approvalStatus = data;
    },
    onDelete() {
      reportsDesignerApi
        .delete(this.$route.query.id)
        .then(() => {
          this.$router.push(`/pages/dev-tools/report-designer`);
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>