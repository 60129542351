<template>
  <section id="dashboard-ecommerce">
    <validation-observer ref="rfqForm" #default="{ invalid }">
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="12" :md="openSection ? '9' : '12'">
            <Alert :error="errors" :success="success" />

            <b-row>
              <b-col>
                <h2>Reports Designer Information</h2>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Code" label-for="v-rpt_name">
                  <v-select
                    id="v-rpt_name"
                    label="name"
                    v-model="formData.rpt_name"
                    :options="modules"
                    :reduce="(modules) => modules.value"
                    @option:selected="selectCode($event)"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Title" label-for="v-rpt_title">
                  <b-form-input v-model="formData.rpt_title" id="v-rpt_title" />
                </b-form-group>
              </b-col>
              <!-- <b-col cols="12" md="4">
                <b-form-group label="PDF Template" label-for="v-pdf_template">
                  <b-form-input
                    v-model="formData.pdf_template"
                    id="v-pdf_template"
                  />
                </b-form-group>
              </b-col> -->
            </b-row>

            <b-row>
              <b-col>
                <b-form-group label="Subtitle">
                  <b-form-textarea v-model="formData.rpt_subtitle" rows="4" />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <h2>Data Source</h2>
              </b-col>

              <b-col cols="6" class="text-right">
                <feather-icon
                  @click="isHidingFormDataSource = !isHidingFormDataSource"
                  class="pin isDisplaying"
                  :class="{
                    arrow_down: isHidingFormDataSource,
                    arrow_up: !isHidingFormDataSource,
                  }"
                  icon="ChevronUpIcon"
                  size="36"
                />
              </b-col>
            </b-row>

            <span v-if="!isHidingFormDataSource">
              <b-row>
                <b-col>
                  <b-form-group label="Command Content" label-for="v-cmd_text">
                    <b-form-textarea
                      placeholder="SELECT * FROM "
                      id="v-cmd_text"
                      v-model="formData.cmd_text"
                      rows="4"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group label="Limit" label-for="v-page_size">
                    <b-form-input
                      id="v-page_size"
                      v-model="formData.page_size"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group label="Column Header">
                    <b-form-textarea
                      placeholder='Ex. {"name":"Ticket No.","field":"ticket_no"}|{"name":"Company","field":"company_name"}'
                      v-model="formData.header_template"
                      rows="4"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Data Type" label-for="v-cmd_data">
                    <b-form-input id="v-cmd_data" v-model="formData.cmd_data" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Command Type" label-for="v-cmd_type">
                    <b-form-input id="v-cmd_type" v-model="formData.cmd_type" />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group label="Command Content" label-for="v-cmd_text">
                    <b-form-textarea
                      id="v-cmd_text"
                      v-model="formData.cmd_text"
                      rows="4"
                    />
                  </b-form-group>
                </b-col>
              </b-row> -->
            </span>

            <!-- <b-row>
              <b-col cols="6">
                <h2>Results</h2>
              </b-col>

              <b-col cols="6" class="text-right">
                <feather-icon
                  @click="isHidingForm = !isHidingForm"
                  class="pin isDisplaying"
                  :class="{ arrow_down: isHidingForm, arrow_up: !isHidingForm }"
                  icon="ChevronUpIcon"
                  size="36"
                />
              </b-col>
            </b-row>

            <span v-if="!isHidingForm">
              <b-row>
                <b-col>
                  <b-form-group
                    label="No. of Items Shown"
                    label-for="v-page_size"
                  >
                    <b-form-input
                      id="v-page_size"
                      v-model="formData.page_size"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group label="Columns" label-for="v-col_title">
                    <b-form-textarea
                      id="v-col_title"
                      v-model="formData.col_title"
                      rows="4"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </span> -->

            <!-- <b-row>
              <b-col cols="6">
                <h2>Template</h2>
              </b-col>

              <b-col cols="6" class="text-right">
                <feather-icon
                  @click="isHidingFormResults = !isHidingFormResults"
                  class="pin isDisplaying"
                  :class="{
                    arrow_down: isHidingFormResults,
                    arrow_up: !isHidingFormResults,
                  }"
                  icon="ChevronUpIcon"
                  size="36"
                />
              </b-col>
            </b-row>

            <span v-if="!isHidingFormResults">
              <b-row>
                <b-col>
                  <b-form-group label="Column Header">
                    <b-form-textarea
                      v-model="formData.header_template"
                      rows="4"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group label="Items">
                    <b-form-textarea
                      v-model="formData.item_template"
                      rows="4"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group label="Footer">
                    <b-form-textarea
                      v-model="formData.footer_template"
                      rows="4"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </span> -->
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-right">
            <b-button
              type="button"
              class="mr-1"
              variant="outline-danger"
              @click="returnToPage"
            >
              Back
            </b-button>
            <b-button
              :disabled="invalid"
              v-b-modal.tab-information
              type="button"
              variant="success"
            >
              Submit
            </b-button>

            <Modal modalId="tab-information" @onChange="(v) => onSubmit()" />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </section>
</template>

<script>
import reportsDesignerApi from '@api/reports_designer';
import { required } from '@validations';

export default {
  name: 'ReportsDesignerInformationPage',
  props: {
    section: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    required,
    success: false,
    openSection: false,
    errors: {},
    isHidingForm: false,
    isHidingFormDataSource: false,
    isHidingFormResults: false,
    formData: {
      id: null,
      status: 'O',
    },
    modules: [
      {
        name: 'Quotation',
        value: 'quotation',
      },
      {
        name: 'RFQ',
        value: 'rfq',
      },
    ],
  }),
  watch: {
    section(v) {
      this.openSection = v;
    },
  },
  mounted() {
    if (+this.$route.query.id) {
      this.loadReportsDesigner();
    }
  },
  methods: {
    loadReportsDesigner() {
      reportsDesignerApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          if (data != null) {
            this.formData = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      const success = () => {
        this.$emit('onTabChange', 1);
        window.scrollTo(0, 0);
        this.success = true;
        this.toastConfig();
        this.loadReportsDesigner();
      };

      const error = () => {
        window.scrollTo(0, 0);
        this.toastConfig('', 'error');
        this.success = false;
        this.errors = err;
      };

      const data = this.formData;

      if (!data.id) {
        reportsDesignerApi
          .add(data)
          .then(({ data }) => {
            const url = `/pages/dev-tools/report-designer/manage?id=${data.id}`;
            this.$router.push(url);
            success();
          })
          .catch((err) => {
            error();
          })
          .finally(() => {
            //
          });
      } else {
        reportsDesignerApi
          .update(data)
          .then(({ data }) => {
            success();
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      }
    },
    returnToPage() {
      this.$router.push(`/pages/dev-tools/report-designer`);
    },
    selectCode(item) {
      if (item.value) {
        var sqlCommand = 'SELECT * FROM ';
        switch (item.value) {
          case 'quotation':
            sqlCommand += 'quotation_headers as qh';
            break;

          case 'rfq':
            sqlCommand += 'rfq_headers as rfqh';
            break;

          default:
            break;
        }

        this.formData.cmd_text = sqlCommand;
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.pin:hover {
  cursor: pointer;
}

.isDisplaying.arrow_down {
  transform: rotate(180deg);
  transition: transform 0.25s linear;
}

.isDisplaying.arrow_up {
  transform: rotate(360deg);
  transition: transform 0.25s linear;
}
</style>
