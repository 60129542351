<template>
  <span>
    <DxDataGrid
      key-expr="id"
      :data-source="dataSource"
      :focused-row-enabled="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-borders="true"
      :row-alternation-enabled="true"
      @init-new-row="handleEvent('init-new-row', $event)"
      @option-changed="handleEvent('option-changed', $event)"
      @row-removed="handleEvent('row-removed', $event)"
    >
      <DxEditing
        :allow-updating="!isDisabled"
        :allow-deleting="!isDisabled"
        :allow-adding="!isDisabled"
        :use-icons="!isDisabled"
        mode="row"
      />

      <DxColumn type="buttons">
        <DxButton v-if="!isDisabled" icon="edit" :onClick="editItem" />
        <DxButton v-if="!isDisabled" name="delete" />
      </DxColumn>

      <DxColumn data-field="caption" />
      <DxColumn data-field="name" />
      <DxColumn data-field="reporttype" name="Report Type" />
      <DxColumn data-field="param_opts" name="Options" />
      <DxColumn data-field="default_val" name="Default Value" />

      <DxColumnChooser :enabled="true" />
      <DxColumnFixing :enabled="true" />
      <DxSorting mode="multiple" />
      <DxFilterRow :visible="true" />
      <DxGroupPanel :visible="true" />
      <DxGrouping :auto-expand-all="false" />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel location="before" :visible="true" />

      <DxToolbar>
        <DxItem location="before" name="columnChooserButton" />
        <DxItem location="before" name="searchPanel" />
        <DxItem name="groupPanel" />

        <DxItem v-if="+$route.query.id" template="addButtonTemplate" />
      </DxToolbar>

      <template #addButtonTemplate>
        <div>
          <b-button
            variant="primary"
            class="text-capitalize"
            @click="addModal = true"
          >
            Add
          </b-button>
        </div>
      </template>
    </DxDataGrid>

    <b-modal
      scrollable
      v-model="addModal"
      centered
      size="lg"
      title="Customer Checklists"
      @hide="closeModal()"
    >
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Caption">
            <b-form-input v-model="formData.caption" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Name">
            <b-form-input v-model="formData.name" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Type" label-for="v-reporttype">
            <b-form-input v-model="formData.reporttype" id="v-reporttype" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Options" label-for="v-param_opts">
            <b-form-input v-model="formData.param_opts" id="v-param_opts" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Default Value" label-for="v-default_val">
            <b-form-textarea
              v-model="formData.default_val"
              id="v-default_val"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- eslint-disable -->
      <template #modal-footer="{ close }">
        <b-button @click="closeModal()" variant="danger" class="bg-darken-4">
          Cancel
        </b-button>
        <b-button @click="onSubmit" variant="primary" class="bg-darken-4">
          Save
        </b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import Ripple from 'vue-ripple-directive';

import reportsParameterApi from '@api/reports_parameter';
import { queryParameters } from '@/schema';

export default {
  name: 'ReportsDesignerParameterPage',
  directives: {
    Ripple,
  },
  components: {},
  computed: {},
  data: () => ({
    dataSource: [],
    isDisabled: false,
    dataIndex: -1,
    productItems: [],
    addModal: false,
    formData: {
      id: 0,
      header_id: 0,
      status: 'O',
    },
  }),
  mounted() {
    if (+this.$route.query.id) {
      this.loadReportsParameter();
    }
  },
  methods: {
    closeModal() {
      this.addModal = false;
      const defaultForm = {
        id: 0,
        header_id: 0,
        status: 'O',
      };

      this.$nextTick(() => {
        this.dataIndex = -1;
        this.formData = Object.assign({}, defaultForm);
      });
    },
    loadReportsParameter() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.filterExt = `header_id=${this.$route.query.id}`;

      reportsParameterApi
        .list(params)
        .then(({ data }) => {
          this.dataSource = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    handleEvent(action, event) {
      if (action == 'row-removed') {
        //
      }
    },
    onReorder(e) {
      const visibleRows = e.component.getVisibleRows();
      const toIndex = this.dataSource.indexOf(visibleRows[e.toIndex].data);
      const fromIndex = this.dataSource.indexOf(e.itemData);
      const newDataSource = [...this.dataSource];

      newDataSource.splice(fromIndex, 1);
      newDataSource.splice(toIndex, 0, e.itemData);

      this.dataSource = newDataSource;
    },
    editItem(event) {
      if (event) {
        this.addModal = true;
        this.$nextTick(() => {
          const data = event.row.data;

          this.dataIndex = event.row.dataIndex;
          this.formData = Object.assign({}, data);
        });
      }
    },
    onSubmit() {
      const data = this.formData;
      data.header_id = +this.$route.query.id;

      if (this.dataIndex < 0) {
        delete data.id;

        reportsParameterApi
          .add(data)
          .then(() => {
            this.toastConfig();
            this.loadReportsParameter();

            this.closeModal();
          })
          .catch((err) => {
            this.toastConfig('', 'error')
          })
          .finally(() => {
            //
          });
      } else {
        reportsParameterApi
          .update(data)
          .then(() => {
            this.toastConfig();
            this.loadReportsParameter();

            this.closeModal();
          })
          .catch((err) => {
            this.toastConfig('', 'error')
          })
          .finally(() => {
            //
          });
      }
    },
  },
};
</script>

<style></style>
